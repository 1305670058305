import React from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../components/structure';
import { Content, Hero } from "../../components/blocks"

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <Hero vulnerability title={'Points Vulnerability Disclosure Program'}/>
    <Content>
      <div  style={{width: '90%', maxWidth: '1080px', margin: 'auto'}}>
      <p style={{textAlign: 'left'}}>
      At Points, we take cyber security and privacy seriously, however we realize that no technology is perfect.  We are committed to protecting the data we receive from our partners and the personal data within our remit, which is why we offer a vulnerability disclosure program.  We believe that working with skilled security researchers across the globe is crucial in identifying weaknesses in any technology.  We care about  information security, and we're ready to work with security researchers to improve the security of our services. Points believes that ethical security research performed in good-faith provides an invaluable public service.
      </p>
      <p style={{textAlign: 'left'}}>
      We are excited for you to participate as a security researcher to help us identify vulnerabilities in our web applications.
      </p>
      <p style={{textAlign: 'left'}}>
      Points operates its vulnerability disclosure program in conjunction with Plusgrade.   Plusgrade & Points have partnered with Bugcrowd to manage the vulnerability disclosure program.  Vulnerabilities submitted to the program will be triaged, assessed and rated according to the <a href="https://bugcrowd.com/vulnerability-rating-taxonomy">Bugcrowd Vulnerability Rating Taxonomy</a>. Researchers must also follow Bugcrowd’s <a href="https://www.bugcrowd.com/resources/essentials/standard-disclosure-terms/">standard disclosure terms</a>.
      </p>
     
      <p style={{textAlign: 'left'}}>The Plusgrade program does not allow for public disclosure and researchers may not release information about vulnerabilities found in this program to the public.
</p>
      
      <p style={{textAlign: 'left'}}>
      The Plusgrade vulnerability program can be accessed on Bugcrowd by following the link: <a href="http://bugcrowd.com/plusgrade-vdp-pro">bugcrowd.com/plusgrade-vdp-pro</a>
      </p>
      <p style={{textAlign: 'left'}}>
      Good luck, and happy hunting!
      </p>
      </div>
    </Content>
  </Layout>
);

export default PrivacyPage;
